<template>
  <section>
    <va-breadcrumbs>
        <va-breadcrumbs-item label="Suas Turmas"  />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: rgb(7, 133, 211)"
            >Listar Tudo da Turma</span
          >
        </va-breadcrumbs-item>
    </va-breadcrumbs>
    <br>
    <div class="form-elements" style="margin-top:20px;">
          <div class="row">
              <div class="col-md-1 col-sm-2 col-icon-univer" >
               <i class="fa fa-university" style="margin-right:10px;color:#2fc982;font-size: 35px;"></i>
              </div>
              <div class="col-md-9 col-sm-10 col-cturm" style="margin-top: 6px;">
                <div v-for="d in turma_dados" :key="d.id">
                  <font class="cabecario-turma">{{d.escola}}</font>
                  <font class="cabecario-turma" >{{d.etapa}}</font>
                  <font class="cabecario-turma">{{d.periodo}}</font>
                  <font class="cabecario-turma">{{d.turma}}</font>
                  <font class="cabecario-turma">{{d.turno}}</font>
                </div>
              </div>
              <div class="col-md-12" style="margin-bottom: 15px;">
                <va-button class="mr-2 mb-2" color="#2fc982"  @click="trocarTurma()" style="float:right;margin-top:20px">
                  {{ $t("Trocar de Turma") }}
                </va-button>
              </div>
          </div>
    </div>
    <div class="row" >
        <h4 style="margin-top:15px;font-weight:bold;font-size:15px;margin-bottom: 20px;">
          Escolha a Disciplina.
        </h4>
        <div class="col-md-12">
          <div class="row" style="margin-bottom:15px;margin-top:15px;" >
            <div class="col-md-2 col-6" style="cursor:pointer;margin-bottom: 12px;" v-for="n in this.disciplina" :key="n" @click="DisciplinaConfig(n)">
              <va-card>
                <div class="flex md12" style="padding:0px;padding-bottom:10px;">
                  <i class="fas fa-book-reader" style="font-size: 48px;text-align: center;width: 100%;color: #4387dd;"  ></i>
                </div>
                <div class="flex md12" style="text-align: center; padding:0px;color: #4387dd;">
                  <p style="margin: 0;color: #626262;font-size: 12px;"><b>Professor(a): {{n.professor}}</b></p>
                  <p class="escolha-nome-disciplina">
                    {{n.disciplina}}
                  </p>
                </div>
              </va-card>
            </div>
          </div>
        </div>
    </div>
    <div class="row" v-if="disciplinaSelecionadaPeloCoord" style="padding-bottom: 22px;">
      <h4 v-if="disciplinaSelecionadaPeloCoord.disciplina" style="color:#4387dd">{{disciplinaSelecionadaPeloCoord.disciplina}}</h4>
      <h5 v-if="disciplinaSelecionadaPeloCoord.professor">Professor(a): <b>{{disciplinaSelecionadaPeloCoord.professor}}</b></h5>
    </div>
    <section class="row" v-if="disciplinaSelecionadaPeloCoord">
        <div>
            <b-tabs content-class="mt-3">
               <b-tab title="Planejamentos" active>
                  <article>
                      <b-tabs content-class="mt-3">
                        <b-tab title="Planejamento Completo" active>
                            <article></article>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title >
                                <div @click="planejandoMes(2),planejandoMes(3),planejandoMes(4)">
                                   Planejamento Professor
                                </div>
                            </template>
                            <article>
                                <b-tabs content-class="mt-3">
                                <b-tab  >
                                    <template v-slot:title >
                                        <div @click="planejandoMes(2),planejandoMes(3),planejandoMes(4)">
                                        1° Periodo
                                        </div>
                                    </template>
                                    <div id="periodo_1">
                                        <h4 style="text-align: center; margin-bottom:20px;padding-top:20px;">CONTEÚDOS  TRABALHADOS</h4>
                                        <div class="table-responsive row">
                                        <div class="col-md-4">
                                            <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th class="th_per2">Data</th>
                                                <th class="th_per2">Fevereiro</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="n in fevereiro" :key="n">
                                                <tr>
                                                <td>
                                                    {{n.dia}}
                                                </td>
                                                <td>
                                                    {{n.texto}}
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        <div class="col-md-4">
                                            <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th class="th_per2">Data</th>
                                                <th class="th_per2">Março</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="n in marco" :key="n">
                                                <tr>
                                                <td>
                                                    {{n.dia}}
                                                </td>
                                                <td>
                                                    {{n.texto}}
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>

                                        <div class="col-md-4">
                                            <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th class="th_per2">Data</th>
                                                <th class="th_per2">Abril</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="n in abril" :key="n">
                                                <tr>
                                                <td>
                                                    {{n.dia}}
                                                </td>
                                                <td>
                                                    {{n.texto}}
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    <va-button @click="pdf_p(1)" color="#fff" style="color:#000 !important" ><i class="fa fa-file-pdf-o"  style="font-size: 30px;padding: 13px;color: #b7b7b7;"></i>
                                        Visualizar
                                    </va-button>
                                </b-tab>
                                <b-tab title="2° Periodo">
                                    <template v-slot:title >
                                        <div @click="planejandoMes(5),planejandoMes(6),planejandoMes(7)">
                                        2° Periodo
                                        </div>
                                    </template>
                                    <div id="periodo_2">
                                      <h4 style="text-align: center;">CONTEÚDOS TRABALHADOS</h4>
                                      <div class="table-responsive row">
                                          <div class="col-md-4">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Maio</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in maio" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                          <div class="col-md-4">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Junho</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in junho" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                          <div class="col-md-4">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Julho</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in julho" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                      </div>
                                    </div>
                                    <va-button @click="pdf_p(2)" color="#fff" style="color:#000 !important" ><i class="fa fa-file-pdf-o"  style="font-size: 30px;padding: 13px;color: #b7b7b7;"></i>
                                        Visualizar
                                    </va-button>
                                </b-tab>
                                <b-tab title="3° Periodo">
                                    <template v-slot:title >
                                        <div @click="planejandoMes(8),planejandoMes(9)">
                                        3° Periodo
                                        </div>
                                    </template>
                                    <div id="periodo_3">
                                      <h4 style="text-align: center;">CONTEÚDOS TRABALHADOS</h4>
                                      <div class="table-responsive row">
                                          <div class="col-md-6">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Agosto</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in agosto" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                          <div class="col-md-6">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Setembro</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in setembro" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                      </div>
                                    </div>
                                    <va-button @click="pdf_p(3)" color="#fff" style="color:#000 !important" ><i class="fa fa-file-pdf-o"  style="font-size: 30px;padding: 13px;color: #b7b7b7;"></i>
                                        Visualizar
                                    </va-button>
                                </b-tab>
                                <b-tab title="4° Periodo">
                                    <template v-slot:title >
                                        <div @click="planejandoMes(10),planejandoMes(11),planejandoMes(12)">
                                        4° Periodo
                                        </div>
                                    </template>
                                    <div id="periodo_4">
                                      <h4 style="text-align: center;">CONTEÚDOS TRABALHADOS</h4>
                                      <div class="table-responsive row">
                                          <div class="col-md-4">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Outubro</th>
                                              </tr>
                                              </thead>
                                          <tbody v-for="n in outubro" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                          <div class="col-md-4">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Novembro</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in novembro" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                          <div class="col-md-4">
                                          <table class="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th class="th_per2">Data</th>
                                                  <th class="th_per2">Dezembro</th>
                                              </tr>
                                              </thead>
                                              <tbody v-for="n in dezembro" :key="n">
                                              <tr>
                                                  <td>
                                                  {{n.dia}}
                                                  </td>
                                                  <td>
                                                  {{n.texto}}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          </div>
                                      </div>
                                    </div>
                                    <va-button @click="pdf_p(4)" color="#fff" style="color:#000 !important" ><i class="fa fa-file-pdf-o"  style="font-size: 30px;padding: 13px;color: #b7b7b7;"></i>
                                        Visualizar
                                    </va-button>
                                </b-tab>
                              </b-tabs>

                            </article>
                        </b-tab>
                      </b-tabs>
                  </article>
               </b-tab>
               <b-tab title="Presenças">
                  <article>
                    <b-tabs content-class="mt-3">
                      <b-tab title="Fevereiro" active>
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_fevereiro" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Março">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_marco" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Abril">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_abril" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Maio">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_maio" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Junho">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_junho" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Julho">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_julho" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Agosto">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_agosto" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Setembro">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_setembro" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Outubro">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_outubro" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Novembro">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_novembro" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                      <b-tab title="Dezembro">
                        <b-tabs content-class="mt-3">
                          <b-tab :title="index" v-for="(n, index) in pes_dezembro" :key="n">
                            <div class="table-responsive row">
                              <div class="col-md-12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th class="th_per2">Aluno</th>
                                      <th class="th_per2">1° Presenca</th>
                                      <th class="th_per2">2° Presenca</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="k in n" :key="k">
                                    <tr>
                                      <td>
                                        {{k.aluno}}
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_1 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_1 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                      <td>
                                        <span v-if="k.presenca_2 == 1 " style="color: #2a870c;font-weight: 700;">Presente</span>
                                        <span v-if="k.presenca_2 == 0 " style="color: #870c23;font-weight: 700;">Faltou</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-tab>
                    </b-tabs>
                  </article>
               </b-tab>
               <b-tab title="Notas">
                  <article>
                    <b-tabs content-class="mt-3">
                      <b-tab title="Notas Completo" active>
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th class="th_per1" style="border-right: 0px solid #fff !important;"></th>
                                <th class="th_per1"></th>
                                <th class="th_per1" style="font-size: 12px;">
                                  Encerrar o período ?
                                  <div>
                                    <va-switch v-model="periodo1_estado" true-inner-label="Encerrado!" false-inner-label="Aberto" @click="periodoEstado(1)" />
                                  </div>
                                </th>
                                <th class="th_per1" style="font-size: 12px;">
                                  Encerrar o período ?
                                  <div>
                                    <va-switch v-model="periodo2_estado" true-inner-label="Encerrado!" false-inner-label="Aberto"  @click="periodoEstado(2)"/>
                                  </div>
                                </th>
                                <th class="th_per1" style="font-size: 12px;">
                                  Encerrar o período ?
                                  <div>
                                    <va-switch v-model="periodo3_estado" true-inner-label="Encerrado!" false-inner-label="Aberto" @click="periodoEstado(3)" />
                                  </div>
                                </th>
                                <th class="th_per1" style="font-size: 12px;">
                                  Encerrar o período ?
                                  <div>
                                    <va-switch v-model="periodo4_estado" true-inner-label="Encerrado!" false-inner-label="Aberto" @click="periodoEstado(4)" />
                                  </div>
                                </th>
                                <th class="th_per1" style="border-right: 0px solid #fff !important;"></th>
                                <th class="th_per1" style="border-right: 0px solid #fff !important;"></th>
                                <th class="th_per1" style="border-right: 0px solid #fff !important;"></th>
                              </tr>
                              <tr>
                                <th class="th_per1">MATRÍCULA</th>
                                <th class="th_per1">NOME DO ALUNO</th>
                                <th class="th_per1">
                                  1° Período
                                </th>
                                <th class="th_per1">2° Período</th>
                                <th class="th_per1">3° Período</th>
                                <th class="th_per1">4° Período</th>
                                <th class="th_per1">Total</th>
                                <th class="th_per1">RECUPERAÇÃO FINAL</th>
                                <th class="th_per1">MF</th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(n, index) of alunoNotas" :key="index" style="text-align:center;">
                                  <td v-if="n">{{n.matricula}}</td>
                                  <td v-if="n">{{n.nome}}</td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{n.aluno_notas_completa.periodo_1}}
                                  </td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{n.aluno_notas_completa.periodo_2}}
                                  </td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{n.aluno_notas_completa.periodo_3}}
                                  </td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{n.aluno_notas_completa.periodo_4}}
                                  </td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{parseInt(n.aluno_notas_completa.periodo_1)+parseInt(n.aluno_notas_completa.periodo_2)+parseInt(n.aluno_notas_completa.periodo_3)+parseInt(n.aluno_notas_completa.periodo_4)}}
                                  </td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{n.aluno_notas_completa.recuperacao_final}}
                                  </td>
                                  <td v-if="n.aluno_notas_completa != null">
                                    {{n.aluno_notas_completa.media_final}}
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab title="Avaliações" >
                        <div class="row" style="padding: 15px;">
                            <div class="col-md-12" style="padding-right: 10px;padding-left: 13px;margin-bottom: 13px;">LEGENDA: </div>
                            <div class="col-md-2" style="display: contents;" >
                              <div style="width:20px;height:20px;background-color:#c000ff;margin-right: 10px;"></div>
                              <div style="padding-right: 10px;padding-right: 16px;">ATIVIDADES ADVESIFICADAS</div>
                            </div>
                            <div class="col-md-2" style="display: contents;">
                              <div style="width:20px;height:20px;background-color:#02cee3;margin-right: 10px;"></div>
                              <div style="padding-right: 10px;padding-right: 16px;">SIMULADO</div>
                            </div>
                            <div class="col-md-2" style="display: contents;">
                              <div style="width:20px;height:20px;background-color:#f9b800;margin-right: 10px;"></div>
                              <div style="padding-right: 10px;padding-right: 16px;">AVALIAÇÃO</div>
                            </div>
                            <div class="col-md-2" style="display: contents;">
                              <div style="width:20px;height:20px;background-color:#000;margin-right: 10px;"></div>
                              <div style="padding-right: 10px;padding-right: 16px;">RECUPERAÇÃO</div>
                            </div>
                        </div>
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th class="th_per1">MATRÍCULA</th>
                                <th class="th_per1">NOME DO ALUNO</th>
                                <th class="th_per1">1° Período</th>
                                <th class="th_per1">2° Período</th>
                                <th class="th_per1">3° Período</th>
                                <th class="th_per1">4° Período</th>
                              </tr>
                            </thead>
                            <tbody v-if="alunoNotas != []">
                                <tr v-for="(n, index) of alunoNotas" :key="index" style="text-align:center;" >
                                  <td v-if="n">{{n.matricula}}</td>
                                  <td v-if="n">{{n.nome}}</td>
                                  <td v-if="n">
                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Atividades Diversificada"
                                      color="primary"
                                      v-if="n.periodo1_atividades_somado"
                                    >
                                      <b style="color:#c000ff">{{n.periodo1_atividades_somado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Simulado"
                                      color="primary"
                                      v-if="n.periodo1_simulado != null"
                                    >
                                     <b style="color:#02cee3"> {{n.periodo1_simulado.simulado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Avaliação"
                                      color="primary"
                                      v-if="n.periodo1_avaliacao != null"
                                    >
                                      <b style="color:#f9b800">{{n.periodo1_avaliacao.avaliacao}}  </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Recuperação"
                                      color="primary"
                                      v-if="n.periodo1_recuperacao != null"
                                    >
                                      <b>{{n.periodo1_recuperacao.recuperacao}}</b>
                                    </va-popover>
                                  </td>
                                  <td v-if="n">
                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Atividades Diversificada"
                                      color="primary"
                                      v-if="n.periodo2_atividades_somado"
                                    >
                                      <b style="color:#c000ff">{{n.periodo2_atividades_somado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Simulado"
                                      color="primary"
                                      v-if="n.periodo2_simulado != null"
                                    >
                                      <b style="color:#02cee3">{{n.periodo2_simulado.simulado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Avaliação"
                                      color="primary"
                                      v-if="n.periodo2_avaliacao != null"
                                    >
                                      <b style="color:#f9b800">{{n.periodo2_avaliacao.avaliacao}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Recuperação"
                                      color="primary"
                                      v-if="n.periodo2_recuperacao != null"
                                    >
                                      <b>{{n.periodo2_recuperacao.recuperacao}}</b>
                                    </va-popover>
                                  </td>
                                  <td v-if="n">
                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Atividades Diversificada"
                                      color="primary"
                                      v-if="n.periodo3_atividades_somado"
                                    >
                                      <b style="color:#c000ff">{{n.periodo3_atividades_somado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Simulado"
                                      color="primary"
                                      v-if="n.periodo3_simulado != null"
                                    >
                                      <b style="color:#02cee3">{{n.periodo3_simulado.simulado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Avaliação"
                                      color="primary"
                                      v-if="n.periodo3_avaliacao != null"
                                    >
                                      <b style="color:#f9b800">{{n.periodo3_avaliacao.avaliacao}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Recuperação"
                                      color="primary"
                                      v-if="n.periodo3_recuperacao != null"
                                    >
                                      <b>{{n.periodo3_recuperacao.recuperacao}}</b>
                                    </va-popover>
                                  </td>
                                  <td v-if="n">
                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Atividades Diversificada"
                                      color="primary"
                                      v-if="n.periodo4_atividades_somado"
                                    >
                                      <b style="color:#c000ff">{{n.periodo4_atividades_somado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Simulado"
                                      color="primary"
                                      v-if="n.periodo4_simulado != null"
                                    >
                                      <b style="color:#02cee3">{{n.periodo4_simulado.simulado}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Avaliação"
                                      color="primary"
                                      v-if="n.periodo4_avaliacao != null"
                                    >
                                      <b style="color:#f9b800">{{n.periodo4_avaliacao.avaliacao}} </b>
                                    </va-popover>

                                    <va-popover
                                      class="mr-2 mb-2"
                                      message="Recuperação"
                                      color="primary"
                                      v-if="n.periodo4_recuperacao != null"
                                    >
                                      <b>{{n.periodo4_recuperacao.recuperacao}}</b>
                                    </va-popover>
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </article>
               </b-tab>
            </b-tabs>
        </div>
    </section>
  </section>
</template>

<script>
import { jsPDF } from "jspdf";
import  html2canvas  from 'html2canvas';
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Nota } from "@/class/nota";
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { Servidores } from "@/class/servidores.js";
import { Presenca } from "@/class/presenca";

export default defineComponent({
    props: {
        id:{
          required: true
        },
        pg:{}
    },
    data () {
      return {
        periodo1_estado: false,
        periodo2_estado: false,
        periodo3_estado: false,
        periodo4_estado: false,

        item: [],
        turma_dados:[],
        disciplinaSelecionadaPeloCoord: null,
        disciplina:[],
        infoVerif:{
          professor_id: null,
          professor_nome: null,
          turma_disciplina_id: null,
          disciplina_id:null,
          disciplina:null,
        },
        turma_id:null,

        janeiro:[],
        fevereiro:[],
        marco:[],
        abril:[],
        maio:[],
        junho:[],
        julho:[],
        agosto:[],
        setembro:[],
        outubro:[],
        novembro:[],
        dezembro:[],
        servidor_login:0,

        pes_janeiro:[],
        pes_fevereiro:[],
        pes_marco:[],
        pes_abril:[],
        pes_maio:[],
        pes_junho:[],
        pes_julho:[],
        pes_agosto:[],
        pes_setembro:[],
        pes_outubro:[],
        pes_novembro:[],
        pes_dezembro:[],

        alunoNotas:[],
      }
    },
    methods:{
      async pdf_p(n){
        //const doc = new jsPDF('l', 'pt', 'a4');
        const doc = new jsPDF('l', 'pt', 'a4', true);

        if(n == 1){
          const p = document.querySelector("#periodo_1");

          await doc.html(p,{x:10,y:0,html2canvas: {scale: 0.5},});

          var pageCount = doc.internal.pages;

          doc.deletePage(9);
          doc.deletePage(8);
          doc.deletePage(7);
          doc.deletePage(6);
          doc.deletePage(5);
          doc.deletePage(4);
          doc.deletePage(3);
          doc.deletePage(2);

          //doc.setFontSize(9);
          //var pageCount = doc.internal.getNumberOfPages();
          //doc.deletePage(pageCount);

          doc.output("dataurlnewwindow");
        }
        if(n == 2){
          const p = document.querySelector("#periodo_2");
          await doc.html(p,{x:10,y:0,html2canvas: {scale: 0.5},});

          var pageCount = doc.internal.pages;

          doc.deletePage(9);
          doc.deletePage(8);
          doc.deletePage(7);
          doc.deletePage(6);
          doc.deletePage(5);
          doc.deletePage(4);
          doc.deletePage(3);
          doc.deletePage(2);
          doc.output("dataurlnewwindow");
        }
        if(n == 3){
          const p = document.querySelector("#periodo_3");
           await doc.html(p,{x:10,y:0,html2canvas: {scale: 0.5},});

          var pageCount = doc.internal.pages;

          doc.deletePage(9);
          doc.deletePage(8);
          doc.deletePage(7);
          doc.deletePage(6);
          doc.deletePage(5);
          doc.deletePage(4);
          doc.deletePage(3);
          doc.deletePage(2);
          doc.output("dataurlnewwindow");
        }
        if(n == 4){
          const p = document.querySelector("#periodo_4");
          await doc.html(p,{x:10,y:0,html2canvas: {scale: 0.5},});

          var pageCount = doc.internal.pages;

          doc.deletePage(9);
          doc.deletePage(8);
          doc.deletePage(7);
          doc.deletePage(6);
          doc.deletePage(5);
          doc.deletePage(4);
          doc.deletePage(3);
          doc.deletePage(2);
          doc.output("dataurlnewwindow");
        }
      },
       async periodoEstado(periodo){
          try{
          let valor = 0;
          if(periodo == 1){
            if(this.periodo1_estado == true){
              valor = 1;
            }else{
              valor = 0;
            }
          }
          if(periodo == 2){
            if(this.periodo2_estado == true){
              valor = 1;
            }else{
              valor = 0;
            }
          }
          if(periodo == 3){
            if(this.periodo3_estado == true){
              valor = 1;
            }else{
              valor = 0;
            }
          }
          if(periodo == 4){
            if(this.periodo4_estado == true){
              valor = 1;
            }else{
              valor = 0;
            }
          }

          let payload = {
            turma_id: this.turma_id,
            disciplina_id:  this.infoVerif.disciplina_id,
            periodo: periodo,
            valor: valor,
          };

          let data = await Nota.EncerrarPeriodoCoordenador(payload);
          }catch(e){

          }
       },
       trocarTurma(){
           this.$router.push({ name: "turmas-coordenador" });
       },
       async turma(){
          try{
            let dt = await Turma.obtemUm(this.id);
            await this.listar(dt.data);
          }catch(e){

         }
       },
       async listar(turma){
         try{
            let turm = {
                turma_id: turma.id,
                etapa_id: turma.segmento_id,
                periodo_id: turma.serie_id,
                escola_id: turma.escola_id,
            }
            let resp_turma = await Turma.aturma(turm);

            this.turma_dados = [];
            this.turma_dados = resp_turma.data;
            await this.disciplinas(turma.id);
         }catch(e){

         }
       },
       async disciplinas(id){
            let novo = {
              turma_id: id,
            };
            this.turma_id = id;
            let data = await Turma.obtemTurmaCoordenadorV1(novo);
            this.disciplina = [];
            this.disciplina = data.data;
        },
        async DisciplinaConfig(n){
          this.infoVerif.professor_id = n.professor_id;
          this.infoVerif.professor_nome = n.professor;
          this.infoVerif.disciplina = n.disciplina;
          this.infoVerif.disciplina_id = n.disciplina_id;
          this.infoVerif.turma_disciplina_id = n.turma_disciplina_id;
          this.disciplinaSelecionadaPeloCoord = n;
          let serv = await Servidores.obtemUm(this.infoVerif.professor_id);
          this.servidor_login = serv.data[0].login_id;

          this.janeiro=[];
          this.fevereiro=[];
          this.marco=[];
          this.abril=[];
          this.maio=[];
          this.junho=[];
          this.julho=[];
          this.agosto=[];
          this.setembro=[];
          this.outubro=[];
          this.novembro=[];
          this.dezembro=[];

          // gera as presenças da turma direcionado a disciplina escolhida
          this.listarPresenca();
          // gera as notas da turma
          this.listarNota();
        },
        async planejamentoProfessor(){
            var today = new Date();
            let esse_ano = today.getFullYear();

            let payload = {
               turma_id: this.turma_id,
               professor_id: this.infoVerif.professor_id,
               disciplina_id: this.infoVerif.disciplina_id,
               ano: esse_ano,
            };

            let data = await PlanejamentoPedagogico.obtemPlanejamentoProf(payload);
        },

        async planejandoMes(mes){
            var today = new Date();
            let esse_ano = today.getFullYear();

            let pay = {
               turma_id: this.turma_id,
               professor_id: this.servidor_login,
               disciplina_id: this.infoVerif.disciplina_id,
               ano: esse_ano,
               mes: mes,
            };

            let data = await PlanejamentoPedagogico.obtemPlanejamentoProfMes(pay);
            if(mes == 1){ this.janeiro = []; this.janeiro = data.data;}
            if(mes == 2){ this.fevereiro = []; this.fevereiro = data.data;}
            if(mes == 3){ this.marco = []; this.marco = data.data;}
            if(mes == 4){ this.abril = []; this.abril = data.data;}
            if(mes == 5){ this.maio = []; this.maio = data.data;}
            if(mes == 6){ this.junho = []; this.junho = data.data;}
            if(mes == 7){ this.julho = []; this.julho = data.data;}
            if(mes == 8){ this.agosto = []; this.agosto = data.data;}
            if(mes == 9){ this.setembro = []; this.setembro = data.data;}
            if(mes == 10){ this.outubro = []; this.outubro = data.data;}
            if(mes == 11){ this.novembro = []; this.novembro = data.data;}
            if(mes == 12){ this.dezembro = []; this.dezembro = data.data;}
        },
        async listarPresenca(){
          this.pes_janeiro = [];
          this.pes_fevereiro =  [];
          this.pes_marco =  [];
          this.pes_abril = [];
          this.pes_maio = [];
          this.pes_junho = [];
          this.pes_julho =  [];
          this.pes_agosto = [];
          this.pes_setembro = [];
          this.pes_outubro =  [];
          this.pes_novembro = [];
          this.pes_dezembro = [];
          let playload = {
            turma_id: this.turma_id,
            disciplina_id: this.infoVerif.disciplina_id,
          }
          let data = await Presenca.buscarPresencaTurmaProfDisc(playload);
          this.pes_janeiro = data.data.janeiro;
          this.pes_fevereiro =  data.data.fevereiro;
          this.pes_marco =  data.data.marco;
          this.pes_abril = data.data.abril;
          this.pes_maio = data.data.maio;
          this.pes_junho = data.data.junho;
          this.pes_julho =  data.data.julho;
          this.pes_agosto = data.data.agosto;
          this.pes_setembro = data.data.setembro;
          this.pes_outubro =  data.data.outubro;
          this.pes_novembro = data.data.novembro;
          this.pes_dezembro = data.data.dezembro;
        },
        async listarNota(){
          this.periodo1_estado = false;
          this.periodo2_estado = false;
          this.periodo3_estado = false;
          this.periodo4_estado = false;
          this.alunoNotas = [];
          var today = new Date();
          let esse_ano = today.getFullYear();
          let data = await Nota.ListarNotaAlunos(this.turma_id,this.infoVerif.disciplina_id,esse_ano);
          this.alunoNotas = data.data;

          if(this.alunoNotas[0]['p1'] == 1){
            this.periodo1_estado = true;
          }
          if(this.alunoNotas[0]['p2'] == 1){
            this.periodo2_estado = true;
          }
          if(this.alunoNotas[0]['p3'] == 1){
            this.periodo3_estado = true;
          }
          if(this.alunoNotas[0]['p4'] == 1){
            this.periodo4_estado = true;
          }
        }
    },
    async beforeMount() {
      await this.turma();
    }
});
</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #3f8771;
    color: #fff;
    border-right: 1px solid #fff;
    border-bottom: 2px solid #fff;
    text-align: center;
    font-weight: 500;
  }
  .th_per2{
    background-color: #3f4487;
    color: #fff;
    border-right: 1px solid #fff;
    border-bottom: 2px solid #fff;
    text-align: center;
    font-weight: 500;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 20px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:13px;
     }
  }

</style>
